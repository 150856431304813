import {COMPONENT_STATUS} from '../enums';
import firebase from 'firebase/app';

const componentMixin = {
    computed: {
        // The component ID
        component_id() {
            if (this.component && this.component.id) return this.component.id;
            return this.$route.params.component_id;
        },
        // If the component is active
        is_component_active() {
            return this.component.status === COMPONENT_STATUS.ACTIVE;
        },
        // If the component is in standby
        is_component_standby() {
            return this.component.status === COMPONENT_STATUS.STANDBY;
        },
        // If the component has been closed/completed
        is_component_completed() {
            return this.component.status === COMPONENT_STATUS.COMPLETED;
        },
        // If the component is setup with rounding
        has_reported_hours_rounding() {
            return !!(this.component.min || this.component.round);
        },
    },
    methods: {
        updateComponent(key, val = null) {
            const value = val ?? firebase.firestore.FieldValue.delete();
            this.$store.dispatch('updateComponentWithId', {
                component_id: this.component_id,
                key,
                value,
            });
        },
    },
};

export default componentMixin;

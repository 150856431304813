import moment from 'moment';
import {momentWithTz} from '@/utils';

// Don't remove this!
import 'moment-duration-format';
import dayjs from 'dayjs';
import {CURRENCY_TOL} from '@/utils/constants';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export function json(value) {
    return JSON.stringify(value, null, 2);
}

export function pluralize(label, time) {
    if (time == 1) return label;
    return label + 's';
}

export function replace(label, from, to) {
    if (!label) return null;
    return label.replace(from, to);
}

export function truncate(str, max, suffix = '...') {
    return str.length < max
        ? str
        : `${str.substr(
              0,
              str.substr(0, max - suffix.length).lastIndexOf(' ')
          )}${suffix}`;
}

export function uppercase(value) {
    return value.toUpperCase();
}
export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function timeAgo(value) {
    return moment(value).fromNow();
}

export function localTime(date) {
    return moment(date + ' Z', 'YYYY-MM-DD HH:mm:ss Z', true).format(
        'D/MM/YYYY HH:mm:ss'
    );
}

export function milliseconds(time) {
    return Math.floor(time / 1000);
}

export function timestamp2moment(value) {
    if (value instanceof Date) {
        return momentWithTz(value);
    } else if (value instanceof Object) {
        return momentWithTz(moment.unix(value.seconds));
    } else {
        return momentWithTz(moment.unix(value));
    }
}

export function timestamp2dayjs(value) {
    if (value instanceof Date) {
        return dayjs(value);
    } else if (value.seconds) {
        return dayjs.unix(value.seconds);
    } else {
        return dayjs.unix(value);
    }
}

export function nFormatter(num, digits) {
    const si = [
        {value: 1e18, symbol: 'E'},
        {value: 1e15, symbol: 'P'},
        {value: 1e12, symbol: 'T'},
        {value: 1e9, symbol: 'G'},
        {value: 1e6, symbol: 'M'},
        {value: 1e3, symbol: 'k'},
    ];
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (
                (num / si[i].value + 0.1)
                    .toFixed(digits)
                    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
            );
        }
    }
    return num.toString();
}

export function percentage(value, decimals) {
    if (!value) {
        value = 0;
    }
    if (!decimals) {
        decimals = 0;
    }
    if (
        value == Number.POSITIVE_INFINITY ||
        value == Number.NEGATIVE_INFINITY
    ) {
        return '-';
    }
    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    value = value + '%';
    return value;
}

export function currency(value, abs = false, includeCents = true) {
    if (typeof value !== 'number' && isNaN(parseFloat(value))) return value;
    else value = parseFloat(value);

    if (abs) {
        value = Math.abs(value);
    }
    if (includeCents && Math.abs(value) < CURRENCY_TOL) {
        // for values like -0.004 we want to return $0.00 rather than -$0.00
        value = +0;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: includeCents ? 2 : 0,
        maximumFractionDigits: includeCents ? 2 : 0,
    });
    return formatter.format(value);
}

export function abs(value) {
    if (typeof value !== 'number') {
        return value;
    }
    return Math.abs(value);
}

export function zero(value) {
    if (typeof value !== 'number') {
        return value;
    }
    return value < 0 ? 0 : value;
}

export function sum(values) {
    return values.reduce(reducer);
}

export function dateformat(value, format = null) {
    if (value === null || value === undefined) return '';
    if (!format) format = 'MMM D, YYYY';

    if (moment.isMoment(value)) {
        return moment(value).format(format);
    } else if (dayjs.isDayjs(value)) {
        return dayjs(value).format(format);
    } else if (typeof value === 'string' || value instanceof String) {
        var date;

        if (value.split(' ').length == 2) date = moment(value);
        else date = moment(value, 'DD-MM-YYYY');

        if (!date.isValid()) return '';
        return date.format(format);
    } else {
        return timestamp2moment(value).format(format);
    }
}

export function isFutureDate(value) {
    return moment(value).isAfter(moment());
}

export function daysDiff(value) {
    return Math.abs(moment(value).diff(moment(), 'days'));
}

export function secondsDiff(date1, date2 = moment().unix()) {
    return Math.abs(moment.unix(date1).diff(moment.unix(date2), 'seconds'));
}

export function milli2hours(value, truncated = false) {
    if (value == null || value == undefined) return null;
    const hours = value / 60 / 60 / 1000;
    return truncated ? hours.toFixed(1) : hours;
}

export function seconds2hours(value, truncated = false) {
    console.log(value);
    if (value == null || value == undefined) return null;
    const hours = value / 60 / 60;
    return truncated ? hours.toFixed(1) : hours;
}

export function hours2milli(value) {
    if (value == null || value == undefined) return null;
    return value * 60 * 60 * 1000;
}

export function hours2seconds(value) {
    if (value == null || value == undefined) return null;
    return value * 60 * 60;
}

export function hours2duration(value) {
    if (
        value === 0 ||
        value === undefined ||
        value === null ||
        Number.isNaN(value)
    )
        return '00:00';
    return moment
        .duration(value, 'hours')
        .format(process.env.VUE_APP_DEBUG === 'true' ? 'hh:mm' : 'hh:mm', {
            trim: false,
        });
}

export function seconds2duration(value) {
    return hours2duration(value / 60 / 60);
}

export function milli2duration(value) {
    return seconds2duration(value / 1000);
}

export function seconds2date(value, format = null) {
    return milli2date(value * 1000, format);
}
export function milli2date(value, format = null) {
    if (value === null || value === undefined) return '';
    if (!format) format = 'HH:mm';

    return moment.utc(value).local().format(format);
}

export function tstamp2moment(tstamp) {
    if (tstamp && tstamp.seconds) {
        return moment.utc(tstamp.seconds * 1000).local();
    } else null;
}

export function averageTimes(times) {
    var count = times.length;
    var timesInSeconds = [];

    for (var i = 0; i < count; i++) {
        var pieces = times[i].split(':');
        var ampm = pieces[2].split(' ');
        var hrs = Number(pieces[0]);
        var mins = Number(pieces[1]);
        var secs = Number(ampm[0]);
        ampm = ampm[1];
        if (ampm == 'PM') hrs = hrs + 12;
        var totalSecs = hrs * 60 * 60;
        totalSecs += mins * 60;
        totalSecs += secs;
        timesInSeconds[i] = totalSecs;
    }

    var total = 0;
    for (var j = 0; j < count; j++) {
        total = total + Number(timesInSeconds[j]);
    }

    var avg = Math.round(total / count);
    var avgMins = Math.floor(avg / 60);
    var avgSecs = avg - 60 * avgMins;
    var avgHrs = Math.floor(avgMins / 60);
    avgMins = avgMins - 60 * avgHrs;

    // var avgAmpm = 'AM';
    // if (avgHrs > 12) {
    //     avgAmpm = 'PM';
    //     avgHrs = avgHrs - 12;
    // }

    avgSecs = ('0' + avgSecs).slice(-2);
    avgMins = ('0' + avgMins).slice(-2);

    return avgHrs + ':' + avgMins; // + ':' + avgSecs + ' ' + avgAmpm;
}

export function diffTimes(start, end) {
    start = start.split(':');
    end = end.split(':');
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24;

    return (
        (hours <= 9 ? '0' : '') +
        hours +
        ':' +
        (minutes <= 9 ? '0' : '') +
        minutes
    );
}

export function flattenFirebaseArray(array) {
    if (!array) return null;
    return Object.keys(array).map((key, index) => {
        return {...{'.key': key}, ...array[key]};
    });
}

export function getParentPath(path) {
    if (path == undefined || !path) return '';
    var the_arr = path.split('/');
    the_arr.pop();
    the_arr.pop();
    return the_arr.join('/');
}

export function getTotalDurationForSessions(sessions) {
    if (!sessions || sessions == undefined) return 0;
    if (sessions.length == 0) return 0;

    const all_sessions = sessions.map((session) => {
        return !session.stop ? 0 : session.stop.seconds - session.start.seconds;
    });

    if (all_sessions.length == 0) return 0;
    return all_sessions.reduce(reducer) * 1000; // return in milli
}

export function getTotalTimeSpentInMillis(object) {
    if (object.time_spent) {
        // Sum all users time spent
        return Object.values(object.time_spent).reduce((acc, value) => {
            return acc + value;
        }, 0);
    }

    return 0;
}

export function getActiveSessionForComponent(user, component) {
    if (user.sessions) {
        let last_session_key = Object.keys(user.sessions)[
            Object.keys(user.sessions).length - 1
        ];
        let last_session = user.sessions[last_session_key];
        if (last_session && !last_session['stop']) {
            // User has an active session
            let active_component_key = last_session.component_path.substr(
                last_session.component_path.lastIndexOf('/') + 1
            );
            if (active_component_key == component['.key']) {
                // User is working on this components
                return last_session;
            }
        }
    }
    return null;
}

export function getUsersWithActiveSession(sessions) {
    return sessions
        .filter((session) => {
            return !session.stop;
        })
        .map((obj) => obj.user);
}

export function getUsersWithActiveSessionForProject(users, project) {
    var objects = [];
    if (!users) return objects;
    for (let user of users) {
        if (user.sessions) {
            let last_session_key = Object.keys(user.sessions)[
                Object.keys(user.sessions).length - 1
            ];
            let last_session = user.sessions[last_session_key];
            if (last_session && !last_session['stop']) {
                // User has an active session
                let active_project_key = last_session.component_path.split(
                    '/'
                )[1];
                if (active_project_key == project['.key']) {
                    // User is working on this components
                    objects.push({user: user, session: last_session});
                }
            }
        }
    }
    return objects;
}

/**
 * Converts a firebase reference to the document ID
 * @param ref - Can be a string (eg. /document/group/document_id) or a ref object
 * @returns {null|string} the document ID
 */
export function fireRef2id(ref) {
    const res = fireRefToRefString(ref);
    if (!res) return null;
    const the_arr = res.split('/');
    return the_arr[the_arr.length - 1];
}

export function fireRefToRefString(ref) {
    if (!ref) return null;

    if (ref instanceof Object) return ref.path;
    else return ref;
}

export function relativeDate(value) {
    if (!value) return null;

    const date = timestamp2moment(value);

    const sameYear = moment().isSame(date, 'year');

    const localised = {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: 'dddd',
        sameElse: `DD/MM${sameYear ? '' : '/YY'}`,
    };

    const nextWeek = moment().add(1, 'week');

    let start = date.calendar(null, localised);

    if (date.isSame(nextWeek, 'week')) {
        start = date.format('[Next] dddd');
    }

    return start;
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        width: "45%",
        "custom-class": "settings-modal",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        closed: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabChange },
          model: {
            value: _vm.active_tab,
            callback: function ($$v) {
              _vm.active_tab = $$v
            },
            expression: "active_tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Integrations", name: "integrations" } },
            [
              _c("integrations-settings", {
                attrs: { show_dialog: _vm.show_dialog },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Profile", name: "profile" } },
            [
              _c("user-profile-settings", {
                attrs: { user: _vm.current_user, show_dialog: _vm.show_dialog },
              }),
            ],
            1
          ),
          _vm.user_is_super_admin
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Organisation", name: "organisation" } },
                [
                  _c("organisation-settings", {
                    attrs: { show_dialog: _vm.show_dialog },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading.logout, type: "danger", plain: "" },
              on: { click: _vm.handleLogout },
            },
            [_vm._v(" Logout ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
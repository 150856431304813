export var USER_ROLES = {
    USER: 'user',
    ADMIN: 'admin',
    SUPER_ADMIN: 'super-admin',
    DELETED: 'deleted',
};

export var PROJECT_STATUS = {
    LEAD: 'lead',
    ACTIVE: 'active',
    MAINTENANCE: 'maintenance',
    ARCHIVED: 'archived',
};

export var STAGE_STATUS = {
    ACTIVE: 'active',
    COMPLETED: 'completed',
};

export var COMPONENT_STATUS = {
    ACTIVE: 'active',
    STANDBY: 'standby',
    COMPLETED: 'completed',
};

export var CR_STATUS = {
    REQUESTED: 0,
    BACKLOG: 1,
    SCOPING: 2,
    PRE_APPROVAL: 3,
    QUOTING: 4,
    CONTRACT: 5,
    IN_PROGRESS: 6,
    CLOSED: 7,
    ARCHIVED: 8,
};

export var INVOICE_STATUS = {
    UNDEFINED: 'UNDEFINED',
    PENDING: 'PENDING',
    AUTHORISED: 'AUTHORISED',
    SCHEDULED: 'SCHEDULED',
    SENT: 'SENT',
    PAID: 'PAID',
    ERROR: 'ERROR',
};

export var ESTIMATE_STATUS = {
    DRAFT: 0, // time is being allocated to subtasks
    PENDING: 1, // final adjustments, ready for sending
    SENT: 4, // quote sent to client & submitted to Xero
    ACCEPTED: 2, // estimate is accepted
    DECLINED: 3, // estimate is declined
};

export var XERO_QUOTE_STATUS = {
    DRAFT: 'DRAFT',
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    DELETED: 'DELETED',
    INVOICED: 'INVOICED',
};

export var RATE_UNIT = {
    HOUR: 'hour',
    DAY: 'day',
};

export var CALENDAR_VIEW_TYPE = {
    DAILY: 0,
    WEEKLY: 1,
    MONTHLY: 2, // not currently in use
    YEARLY: 3,
};

export var PAYMENT_PLAN_FREQUENCY = {
    WEEK: 'week',
    MONTH: 'month',
};

export var PAYMENT_PLAN_STATUS = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    PLANNED: 'PLANNED',
    INVOICED: 'INVOICED',
    PAID: 'PAID',
};

export var PAYMENT_PLAN_INVOICE_STATUS = {
    DRAFT: 'DRAFT',
    REVIEW: 'REVIEW',
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
};

export var PAYMENT_PLAN_DUE_TERMS = {
    DAYS_AFTER_DATE: 0,
    DAYS_AFTER_END_OF_MONTH: 1,
    DAY_OF_MONTH: 2,
    DAY_OF_NEXT_MONTH: 3,
};

export var DUE_STATUS = {
    PENDING: 0,
    DUE: 1,
    OVERDUE: 2,
};

export var TEMPLATE_TYPES = {
    ESTIMATE: 'estimate',
    DOCUMENT: 'document',
};

export var DOCUMENT_TYPES = {
    ESTIMATE: 'estimate',
    SUMMARY: 'summary',
    // external links
    MIRO: 'miro',
    FIGMA: 'figma',
    CONFLUENCE: 'confluence',
    GOOGLE_DOC: 'google-doc',
    PDF: 'pdf',
    // generic external link
    EXTERNAL: 'external',
};

export var DOCUMENT_GROUP_TOGGLES = {
    EXCLUDED: 'excluded',
    SUBTASK_TIME: 'subtask_time',
};

export var DOCUMENT_GROUP_DETAIL = {
    SUBTASKS: 'subtasks',
    DAYS: 'days',
    HOURS: 'hours',
};

export var DOCUMENT_SUMMARY_TOGGLES = {
    TASK_COST: 'task_cost',
    EXCLUDED: 'excluded',
    GST: 'gst',
};

export var DOCUMENT_SUMMARY_DETAIL = {
    TASKS: 'tasks',
    DAYS: 'days',
    HOURS: 'hours',
    COST: 'cost',
};

export var DOCUMENT_BLOCK_TYPES = {
    COVER_SHEET: 'cover_sheet',
    TEXT: 'text',
    PAGE_BREAK: 'page_break',
    AGREEMENT: 'agreement',
    ESTIMATE_GROUPS: 'estimate_groups',
    ESTIMATE_GROUP: 'estimate_group',
    ESTIMATE_SUMMARY: 'estimate_summary',
    JIRA_LIST: 'jira_list',
    MODULE_LIST: 'module_list',
    AI_TEXT: 'ai_text',
    SUMMARY_COVER_SHEET: 'summary_cover_sheet',
};

export const DOCUMENT_AI_MODES = {
    SUMMARISE_DOCUMENT: 'summarise_document',
    PROMPT_ONLY: 'prompt_only',
};
export const ISSUE_CATEGORIES = Object.freeze({
    BUG: 'Bug',
    QA: 'QA',
    ACTIVITY: 'Activity',
    DESIGN: 'Design',
    ADMIN: 'Admin',
    TASK: 'Task',
    DEV: 'Dev',
    FEATURE: 'Feature',
    ACTION: 'Action',
    STORY: 'Story',
    EPIC: 'Epic',
    DEFAULT: '',
    TODO: 'Todo',
});

export const ISSUE_ICONS = Object.freeze({
    [ISSUE_CATEGORIES.BUG]: 'jira-bug',
    [ISSUE_CATEGORIES.QA]: 'jira-qa',
    [ISSUE_CATEGORIES.ACTIVITY]: 'jira-activity',
    [ISSUE_CATEGORIES.DESIGN]: 'jira-design',
    [ISSUE_CATEGORIES.ADMIN]: 'jira-admin',
    [ISSUE_CATEGORIES.TASK]: 'jira-task',
    [ISSUE_CATEGORIES.DEV]: 'jira-dev',
    [ISSUE_CATEGORIES.FEATURE]: 'jira-feature',
    [ISSUE_CATEGORIES.ACTION]: 'jira-action',
    [ISSUE_CATEGORIES.STORY]: 'jira-story',
    [ISSUE_CATEGORIES.EPIC]: 'jira-epic',
    [ISSUE_CATEGORIES.DEFAULT]: 'jira-default',
    [ISSUE_CATEGORIES.TODO]: 'todo-icon',
});

export const ESTIMATE_TYPES = Object.freeze({
    EXPLORE: 'Explore',
    CREATE: 'Create',
});

export const RECURRING_UNITS = Object.freeze({
    DAY: 'day',
    MONTH: 'month',
});

export const COMMANDS = Object.freeze({
    NAVIGATE: 'navigate',
    SETTINGS: 'settings',
    ADDTODO: 'addtodo',
    TIMER: 'timer',
    NEW_ESTIMATE: 'new_estimate',
    NEW_ESTIMATE_DOCUMENT: 'new_estimate_document',
    NEW_DOCUMENT_FROM_LINK: 'new_document_from_link',
});
export const COMMAND_DEPENDENCIES = Object.freeze({
    COMMAND: 'command',
    NAVIGATION: 'navigation',
    PROJECT: 'project',
    STAGE: 'stage',
    COMPONENT: 'component',
    CHANGE_REQUEST: 'change_request',
    ESTIMATE: 'estimate',
    PAYMENT_PLAN: 'payment_plan',
    INVOICE: 'invoice',
    TIMER: 'timer',
    DOCUMENT: 'document',
});

export const KEYWORDS = Object.freeze({
    CODEFISH_FOOTER: 'codefish',
});

export const EMAIL_TYPE = Object.freeze({
    ADDRESSEE: 'addressee',
    CC: 'cc',
    BCC: 'bcc',
});

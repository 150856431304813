<template>
    <div
        class="time-indicator desktop-only"
        @mouseover="disable_hover ? null : (hover = true)"
        @mouseleave="disable_hover ? null : (hover = false)"
    >
        <span v-if="hover && time_estimate" class="time">
            <span>
                <strong>{{ remaining | milli2duration }}</strong>
            </span>
            <span>left</span>
        </span>
        <div v-else class="circle">
            <div class="inner" :style="{backgroundColor: color}">
                <div :class="ring_class" class="ring" />
            </div>
        </div>
    </div>
</template>

<script>
import componentMixin from '@/mixins/component.mixin';
import componentSessionMixin from '@/mixins/component.session.mixin';
import componentRecurringMixin from '@/mixins/component.recurring.mixin';

import {momentWithTz} from '@/utils';

export default {
    name: 'time-indicator',
    mixins: [componentMixin, componentSessionMixin, componentRecurringMixin],
    props: {
        component: {
            type: Object,
            required: true,
        },
        disable_hover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hover: false,
        };
    },
    computed: {
        selected_block() {
            return this.getRecurringBlockForDate(momentWithTz());
        },
        current_block() {
            return this.selected_block;
        },
        remaining() {
            if (this.component.recurring) {
                return this.time_available;
            } else if (this.has_reported_hours_rounding) {
                return this.time_left_reportable;
            }

            return this.time_left;
        },
        percentage() {
            if (this.component.recurring) {
                return this.recurring_time_available_percentage;
            } else if (this.has_reported_hours_rounding) {
                return this.time_left_reportable_percentage;
            }

            return this.time_left_percentage;
        },
        color() {
            if (this.percentage === 0) return "rgba('#dcdfe6', 0.4)";

            const percentColors = [
                {pct: 0, color: {r: 0xf9, g: 0x21, b: 0x39}}, // Red
                {pct: 10, color: {r: 0xf9, g: 0x21, b: 0x39}}, // Red
                {pct: 40, color: {r: 0xf5, g: 0xa6, b: 0x23}}, // Orange
                {pct: 60, color: {r: 0x90, g: 0xd0, b: 0x50}}, // Green
                {pct: 100, color: {r: 0x90, g: 0xd0, b: 0x50}}, // Green
            ];

            // If percentage is negative, force red (first color)
            if (this.percentage < 0) {
                const red = percentColors[0].color;
                return `rgb(${red.r},${red.g},${red.b})`;
            }

            // Find the lower and upper color bounds based on this.percentage
            let upperIndex = percentColors.findIndex(
                ({pct}) => this.percentage < pct
            );

            // Default to last segment if percentage exceeds all defined pct values
            if (upperIndex === -1) {
                upperIndex = percentColors.length - 1;
            }

            // Lower and upper color stops
            const lower = percentColors[upperIndex - 1];
            const upper = percentColors[upperIndex];

            // Calculate interpolation factor between the two colors
            const range = upper.pct - lower.pct;
            const rangePct = (this.percentage - lower.pct) / range;
            const pctLower = 1 - rangePct;
            const pctUpper = rangePct;

            const color = {
                r: Math.floor(
                    lower.color.r * pctLower + upper.color.r * pctUpper
                ),
                g: Math.floor(
                    lower.color.g * pctLower + upper.color.g * pctUpper
                ),
                b: Math.floor(
                    lower.color.b * pctLower + upper.color.b * pctUpper
                ),
            };

            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
        },
        ring_class() {
            if (this.percentage === 0) return null;
            if (this.percentage > 0 && this.percentage < 20) return 'ring-slow';
            if (this.percentage < 20) return 'ring-fast';

            return null;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.time-indicator {
    width: 40px;
    height: 30px;
    padding: 0 15px;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid $border-grey-light;
        background-color: $white-dark;
        padding: 2px;

        .inner {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: relative;

            .ring {
                position: absolute;
                top: 50%;
                margin-top: -8px;
                left: 50%;
                margin-left: -8px;
                width: 16px;
                height: 16px;
                border-radius: 8px;
                opacity: 0;
                z-index: 10;
            }

            .ring-slow {
                box-shadow: 0 0 2px 1px $red, inset 0 0 2px 1px $red;
                animation: ring 2s ease-in-out;
                animation-iteration-count: infinite;
            }

            .ring-fast {
                box-shadow: 0 0 2px 1px $red, inset 0 0 2px 1px $red;
                animation: ring 0.7s ease-in-out;
                animation-iteration-count: infinite;
            }
        }

        @keyframes ring {
            0% {
                transform: scale(0.4, 0.4);
                opacity: 0;
            }
            50% {
                opacity: 0.6;
            }
            100% {
                transform: scale(1.4, 1.4);
                opacity: 0;
            }
        }
    }

    .time {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-size: 8px;

            strong {
                font-size: 9px;
                font-weight: 600;
                margin-right: 1px;
            }
        }
    }
}
</style>

<template>
    <section
        class="content-block"
        :class="{
            collapsed: !is_expanded,
            collapsable: collapsable,
            no_background: no_background,
            outlined: outlined,
        }"
    >
        <el-row v-if="title" class="head">
            <el-col :lg="12" :sm="24" class="left-col">
                <slot name="title_content_prefix" />
                <label>{{ title }}</label>
                <slot name="title_content_suffix" />
            </el-col>

            <el-col v-if="$slots.right" :lg="12" :sm="24" class="right-col">
                <div :class="{collapsable: collapsable}">
                    <slot name="right" />
                </div>
                <span v-if="collapsable" @click="is_expanded = !is_expanded">
                    <svgicon name="triangle" class="arrow" />
                </span>
            </el-col>
        </el-row>

        <slot v-if="is_expanded" />
    </section>
</template>

<script>
export default {
    name: 'content-block',
    props: {
        title: {
            type: String,
            default: null,
        },
        collapsable: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: true,
        },
        no_background: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            is_expanded: this.expanded,
        };
    },
    watch: {
        expanded: function (val) {
            this.is_expanded = val;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
section {
    margin: 0 0 10px 0;
    padding: 12px;
    border-radius: 5px;
    background-color: $grey;

    @media screen and (max-width: 992px) {
        padding-top: 20px;

        padding-left: 0px;
        padding-right: 0px;
    }

    .head {
        border-bottom: 1px solid $border-grey-light;
        padding: 0 12px 12px 12px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        user-select: none;

        label {
            line-height: 28px;
            margin-right: 10px;
        }

        hr {
            width: 50%;
            margin: 15px 0;
            border: none;
            height: 1px;
            background-color: $border-grey-light;
        }

        .left-col {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .right-col {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .collapsable {
                margin-right: 50px;
            }

            span {
                padding: 20px 30px;
                position: absolute;
                margin-top: -20px;
                right: -10px;
                padding-top: 22px;
                cursor: pointer;

                .arrow {
                    transform: rotate(270deg);
                    width: 10px;
                    opacity: 0.4;
                }
            }
        }
    }

    &.collapsed {
        .head {
            border: none;
            margin: 0;
            padding: 0;

            span {
                .arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &.no_background {
        background-color: transparent;
    }
    &.outlined {
        border: 1px solid $border-grey;
    }
}
</style>

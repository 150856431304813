<template>
    <nav>
        <div class="medium_wrapper">
            <div
                class="logo-wrapper"
                :class="{'has-org-logo': has_org_logo}"
                @click="navigateToHome"
            >
                <svgicon name="logo-text" class="logo" />
                <org-logo
                    v-if="has_org_logo"
                    :url="$store.state.organisation.logo"
                />
            </div>

            <el-menu
                :default-active="active_index"
                mode="horizontal"
                class="desktop-only"
            >
                <el-menu-item
                    v-for="i in items"
                    :key="i.path"
                    v-only-super-admin="i.private"
                    :index="i.path"
                >
                    <router-link :to="i.path" draggable="false">
                        {{ i.label }}
                    </router-link>
                </el-menu-item>
            </el-menu>

            <div class="right desktop-only">
                <search-input />
                <a class="notification-icon" @click="handleViewNotifications">
                    <el-badge
                        :hidden="!unread_notification_count"
                        :value="unread_notification_count"
                        class="badge"
                    >
                        <i class="el-icon-bell" />
                    </el-badge>
                    <notification-list
                        v-if="show_notifications"
                        :notifications="notifications"
                        @close="handleCloseNotifications"
                        @click="handleNavigateNotification"
                    />
                </a>
                <a class="profile-icon" @click="handleUserProfile">
                    <svgicon name="settings" />
                </a>
            </div>
            <div class="right mobile-only">
                <a class="menu-icon" @click="toggleMenu">
                    <svgicon name="menu" />
                </a>
            </div>
        </div>

        <el-menu
            :default-active="active_index"
            mode="vertical"
            :style="{height: show_menu ? `${mobile_items.length * 56}px` : '0'}"
            class="mobile-only"
        >
            <el-menu-item
                v-for="i in mobile_items"
                :key="i.path"
                v-only-super-admin="i.private"
                :index="i.path"
            >
                <router-link :to="i.path" draggable="false">
                    {{ i.label }}
                </router-link>
            </el-menu-item>
        </el-menu>
    </nav>
</template>

<script>
import dayjs from 'dayjs';

import SearchInput from '@/components/search/SearchInput';
import OrgLogo from '@/components/generic/OrgLogo';
import NotificationList from './NotificationList';

export default {
    name: 'navbar',
    components: {
        SearchInput,
        OrgLogo,
        NotificationList,
    },
    data() {
        return {
            items: [
                {
                    path: '/projects',
                    label: 'PROJECTS',
                    private: false,
                    mobile: true,
                    enabled: true,
                },
                {
                    path: '/planner',
                    label: 'PLANNER',
                    private: false,
                    mobile: false,
                    enabled: this.$store.getters.is_planner_enabled,
                },
                {
                    path: '/sessions',
                    label: 'SESSIONS',
                    private: false,
                    mobile: true,
                    enabled: true,
                },
                {
                    path: '/manage',
                    label: 'MANAGEMENT',
                    private: true,
                    mobile: true,
                    enabled: true,
                },
            ].filter((x) => x.enabled),
            show_menu: false,
            show_notifications: false,
        };
    },
    computed: {
        notifications() {
            return this.$store.state.notifications;
        },
        unread_notification_count() {
            return this.$store.state.notifications.filter((n) => !n.read)
                .length;
        },
        item() {
            let matched = this.$route.matched.filter(
                (i) => i.meta.title || i.meta.key
            );
            if (matched.length > 0) return matched[0];
            return null;
        },
        active_index() {
            if (!this.item) return '/';
            switch (this.item.path.split('/')[1]) {
                case 'project':
                    return '/projects';
                default:
                    return `/${this.item.path.split('/')[1]}`;
            }
        },
        mobile_items() {
            return this.items.filter((item) => item.mobile);
        },
        has_org_logo() {
            return !!this.$store.state.organisation.logo;
        },
    },
    watch: {
        $route() {
            this.show_menu = false;
        },
        notifications(val) {
            if (val.length > 0) {
                if (
                    !val[0].read &&
                    dayjs(val[0].created_at.toDate()).isAfter(
                        dayjs().add(-1, 'minutes')
                    )
                ) {
                    const n = new Notification(val[0].title, {
                        body: val[0].description,
                        tag: `todo/${val[0].id}`,
                    });
                    const notification = val[0];
                    n.onclick = () => {
                        this.handleNavigateNotification(notification);
                        n.close();
                    };
                }
            }
        },
    },
    methods: {
        handleUserProfile() {
            this.$bus.$emit('modal:action', {
                modal: 'settings-modal',
                show: true,
            });
        },
        handleViewNotifications() {
            this.show_notifications = true;
        },
        handleCloseNotifications() {
            this.show_notifications = false;
        },
        toggleMenu() {
            this.show_menu = !this.show_menu;
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
        handleNavigateTodo(notification) {
            if (notification.project) {
                const project = this.$store.getters.projectWithId(
                    notification.project
                );
                this.$router
                    .push({
                        name: 'project_detail_overview',
                        params: {
                            project_id: project.id,
                            notification_todo_id: notification.target_id,
                        },
                    })
                    .catch((e) => {
                        if (e.name === 'NavigationDuplicated') {
                            this.$bus.$emit(
                                `todo:${notification.target_id}:highlight`
                            );
                        }
                    });
            } else {
                this.$router
                    .push({
                        name: 'home',
                        params: {
                            notification_todo_id: notification.target_id,
                        },
                    })
                    .catch((e) => {
                        if (e.name === 'NavigationDuplicated') {
                            this.$bus.$emit(
                                `todo:${notification.target_id}:highlight`
                            );
                        }
                    });
            }
        },
        handleNavigatePaymentPlanInvoice(notification) {
            const project = this.$store.getters.projectWithId(
                notification.project
            );
            this.$router
                .push({
                    name: 'project_detail_paymentplan_invoice',
                    params: {
                        project_id: project.id,
                        invoice_id: notification.target_id,
                    },
                })
                .catch((e) => {
                    if (e.name !== 'NavigationDuplicated') {
                        console.log(e);
                    }
                });
        },
        handleNavigateEstimate(notification) {
            const project = this.$store.getters.projectWithId(
                notification.project
            );
            this.$router
                .push({
                    name: 'project_detail_estimate+detail',
                    params: {
                        project_id: project.id,
                        estimate_id: notification.target_id,
                    },
                })
                .catch((e) => {
                    if (e.name !== 'NavigationDuplicated') {
                        console.log(e);
                    }
                });
        },
        handleNavigateNotification(notification) {
            if (!notification.read) {
                this.$fire
                    .doc(`notifications/${notification.id}`)
                    .update({read: true});
            }

            switch (notification.type) {
                case 'TODO':
                    this.handleNavigateTodo(notification);
                    break;
                case 'INV-REMINDER':
                    this.handleNavigatePaymentPlanInvoice(notification);
                    break;
                case 'ESTIMATE_ACCEPTED':
                case 'ESTIMATE_DECLINED':
                case 'JIRA-TICKET-SUCCESS':
                case 'JIRA-TICKET-FAIL':
                case 'TODO-FAIL':
                case 'TODO-SUCCESS':
                    this.handleNavigateEstimate(notification);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
nav {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 3200;

    @media screen and (max-width: 992px) {
        top: auto;
        position: relative;
    }

    background-color: white;
    border-bottom: solid 1px $border-grey-light;

    .medium_wrapper {
        min-height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: 992px) {
            padding: 0 5px;
        }
    }

    ::v-deep .logo-wrapper {
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 60px;
        margin: 0 30px 0 10px;

        @media screen and (max-width: 992px) {
            margin: 0 10px;
        }

        &.has-org-logo {
            display: flex;
            flex-direction: column;

            .logo {
                width: 45px;
                margin-bottom: 3px;
            }

            img {
                max-width: 120px;
                max-height: 20px;
            }
        }

        .logo {
            fill: $black-dark;
            width: 60px;
        }
    }

    .el-menu {
        flex: 1;
        min-height: 60px;
        border-radius: 0px !important;
        border: none;

        @media screen and (max-width: 992px) {
            box-shadow: inset 0 1px 0 0 $border-grey-light;
            min-height: 0;
            overflow: hidden;
            transition: all 0.3s ease-out;

            .el-menu-item {
                a {
                    padding: 0 15px;
                }
            }
        }

        .el-menu-item {
            padding: 0;

            @media screen and (max-width: 992px) {
                border-left: 2px solid $white;

                &.is-active {
                    border-left: 2px solid $blue-light;
                }
            }

            a {
                color: $black;
                display: block;
                padding: 0 20px;

                @media screen and (max-width: 992px) {
                    padding: 0 10px;
                    font-size: 13px;
                }
            }
        }

        a {
            text-decoration: none;
            user-select: none;
        }
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-right: 10px;

        &.mobile-only {
            flex: 1;
        }

        .profile-icon {
            width: 45px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid white;
            box-sizing: border-box;

            &:hover {
                border: 1px solid $border-grey;
            }

            svg {
                width: 30px;
                fill: $black;
                transform: rotate(90deg);
            }
        }

        .menu-icon {
            width: 45px;
            height: 40px;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            svg {
                width: 15px;
                fill: $black;
            }
        }
    }
}

.notification-icon {
    cursor: pointer;
    position: relative;
    padding: 0 10px;
    margin-right: 10px;

    i {
        transition: all 0.15s ease-in-out;

        &:hover {
            color: $blue;
            transform: scale(1.05);
        }
    }
}
</style>

import {momentWithTz} from '@/utils';

import componentMixin from '@/mixins/component.mixin';

const componentRecurringMixin = {
    mixins: [componentMixin],
    data() {
        return {
            recurring_blocks: [],
        };
    },
    computed: {
        // Header values
        // --------------------------

        time_allocated_calculations() {
            if (!this.selected_block) return null;
            return [
                {
                    value: this.selected_block.time_allocated,
                    offset: false,
                    label: 'allocated',
                },
                {
                    value: -this.time_debt,
                    offset: true,
                    label: 'overtime',
                },
                {
                    value: this.selected_block.rollover_expiring ?? 0,
                    color: '#f5a623',
                    label: 'expiring',
                },
                {
                    value:
                        this.time_rollover -
                        (this.selected_block.rollover_expiring ?? 0),
                    offset: true,
                    label: 'rollover',
                },
                {
                    value: this.time_adjustment,
                    color: '#1989fa',
                    label: 'adjustment',
                },
            ];
        },

        // User tracking
        // --------------------------

        /**
         * Returns an array of tracked times per date for each user within the selected block period.
         *
         * This filters and sorts entries from `time_reportable`, which tracks time data by date.
         *
         * Entries are only included if:
         * - The date falls within the currently selected period (`selected_start` to `selected_end`).
         * - There is at least some non-zero time tracked on that date.
         *
         * The result is sorted chronologically by date.
         *
         * @returns {Array<[string, Object]>} An array of date-time tracking pairs, where each item is a [dateString, userTimeObject] pair.
         *                                    Dates are strings (e.g., '2025-03-05') and userTimeObject maps user IDs to time values.
         *                                    Returns an empty array if no component or time data is available.
         */
        users_time_reportable_current_block() {
            if (!this.component?.time_reportable) return [];
            return Object.entries(this.component.time_reportable)
                .filter(([key, value]) => {
                    const date = momentWithTz(key);
                    if (date.startOf('day').toDate() < this.selected_start)
                        return false;
                    if (date.isAfter(this.selected_end)) return false;

                    return Object.values(value).reduce((a, c) => a + c, 0) > 0;
                })
                .sort(([a], [b]) => {
                    if (a > b) return 1;
                    if (b > a) return -1;
                    return 0;
                });
        },

        // Flags
        // --------------------------

        // If the selected recurring block is the current
        is_current_block() {
            return this.selected_block === this.current_block;
        },
        // If the selected recurring block is in the future
        is_future() {
            if (!this.selected_block) return false;
            return this.selected_block.start_date.toDate() > new Date();
        },
        // If the selected recurring block is in the past
        is_past() {
            if (!this.selected_block) return false;
            return this.selected_block.end_date.toDate() < new Date();
        },

        selected_start() {
            const start = this.selected_block?.start_date ?? null;
            if (start) return momentWithTz(start.toDate());
            return null;
        },
        selected_end() {
            const end = this.selected_block?.end_date ?? null;
            if (end) return momentWithTz(end.toDate());
            return null;
        },

        // Time
        // --------------------------

        /**
         * Gets the snapshot of positive rollover hours carried over from previous blocks.
         *
         * This represents the surplus time (positive rollover) from the previous block, if any.
         * Negative rollovers (time debt) are ignored here.
         *
         * @returns {number} The amount of positive time rollover into the current block. Returns 0 if no block is selected or if there is no positive rollover.
         */
        time_rollover() {
            if (!this.selected_block) return 0;
            return this.selected_block.rollover > 0
                ? this.selected_block.rollover
                : 0;
        },

        /**
         * Calculates the total time allocated for the selected block.
         *
         * This is derived from:
         * - The base `time_allocated` value for the selected block.
         * - Plus any positive time rollover from the previous block.
         * - Minus any time debt (negative rollover) from the previous block.
         * - Plus any time adjustments applied to the current block.
         *
         * @returns {number} The total time allocated for the selected block, accounting for rollover, debt, and adjustments. Returns 0 if no block is selected.
         */
        time_available() {
            if (!this.selected_block) return 0;
            return (
                this.active_time_available +
                this.active_time_expiring +
                this.active_time_rollover
            );
        },

        /**
         * Calculates the total time allocated for the selected block.
         *
         * This value is computed as:
         * - The base `time_allocated` for the selected block.
         * - Plus any positive time rollover from previous blocks (`time_rollover`).
         * - Minus any time debt carried over from previous blocks (`time_debt`).
         * - Plus any time adjustments applied to the current block (`time_adjustment`).
         *
         * @returns {number} The total allocated time for the selected block, adjusted for rollovers, debts, and adjustments. Returns 0 if no block is selected.
         */
        time_allocated() {
            if (!this.selected_block) return 0;
            return (
                this.selected_block.time_allocated +
                this.time_rollover -
                this.time_debt +
                this.time_adjustment
            );
        },

        /**
         * Gets the snapshot of overtime carried over (time debt) from the previous block.
         *
         * This represents the negative rollover time from the previous block, if any.
         * Positive rollovers (surplus time) are ignored.
         *
         * @returns {number} The amount of time debt (negative rollover) carried into the current block. Returns 0 if there is no time debt.
         */
        time_debt() {
            if (!this.selected_block) return 0;
            return this.selected_block.rollover < 0
                ? -this.selected_block.rollover
                : 0;
        },

        /**
         * Calculates the total time adjustment for the current block.
         *
         * This sums all entries in the `time_adjustment` array of the selected block,
         * where each entry contains an amount of time to be adjusted.
         *
         * @returns {number} Total adjusted time for the current block. Returns 0 if no adjustments exist.
         */
        time_adjustment() {
            if (!this.selected_block) return 0;
            if (!this.selected_block?.time_adjustment) return 0;
            return this.selected_block.time_adjustment.reduce((sum, entry) => {
                return sum + entry.time;
            }, 0);
        },

        /**
         * Calculates the total amount of time spent during the current block period.
         *
         * This includes:
         * - Time tracked from `users_time_reportable_current_block`, which aggregates
         *   time reported by users on each day within the current block.
         * - Time from actively running trackers (`tspent__active_sessions`), if the
         *   block is currently active (`is_current_block` is true).
         *
         * Each day's time entries are structured as objects mapping session IDs to
         * time spent in seconds (or minutes, depending on your system).
         *
         * @returns {number} Total time spent in the current block, including active sessions if the block is still ongoing.
         */
        active_time_spent_reportable() {
            if (!this.selected_block) return 0;
            const total_tracked = this.users_time_reportable_current_block.reduce(
                (sum, day) => {
                    const sessions = Object.entries(day[1]);
                    return sessions.reduce((daysum, session) => {
                        return daysum + session[1];
                    }, sum);
                },
                0
            );
            return (
                total_tracked +
                (this.is_current_block ? this.tspent__active_sessions : 0)
            );
        },

        /**
         * Calculates the amount of time left from expiring rollover hours for the selected block.
         *
         * This covers any unused expiring rollover time carried into the block, adjusted by:
         * - Time already spent (`active_time_spent_reportable`).
         * - Any negative time adjustments (`time_adjustment`), which reduce the available expiring time.
         *
         * The result will never be negative (minimum is zero).
         *
         * @returns {number} The remaining expiring rollover time for the selected block. Returns 0 if no block is selected.
         */
        active_time_expiring() {
            if (!this.selected_block) return 0;
            // calculate unused expiring time for past block
            // (minus adjustment, if negative)
            return Math.max(
                (this.selected_block.rollover_expiring ?? 0) -
                    this.active_time_spent_reportable +
                    Math.min(this.time_adjustment, 0),
                0
            );
        },

        /**
         * Calculates the amount of non-expiring rollover time left for the selected block.
         *
         * This works as follows:
         * - If there is any unused expiring rollover time (`active_time_expiring` > 0),
         *   the full non-expiring rollover is shown (total rollover minus expiring rollover).
         * - If no expiring rollover remains, the returned value is the total rollover time
         *   minus the time already spent (`active_time_spent_reportable`), adjusted by any
         *   negative time adjustments (`time_adjustment`).
         * - The result will never be negative (minimum is zero).
         *
         * @returns {number} The amount of non-expiring rollover time left for the selected block. Returns 0 if no block is selected.
         */
        active_time_rollover() {
            if (!this.selected_block) return 0;
            // calculate unused rollover for block
            if (this.active_time_expiring > 0) {
                // is there is expiring remaining, display full rollover
                return (
                    (this.selected_block.rollover ?? 0) -
                    (this.selected_block.rollover_expiring ?? 0)
                );
            } else {
                // otherwise display rollover minus time spent
                // (minus adjustment, if negative)
                return Math.max(
                    (this.selected_block.rollover ?? 0) -
                        this.active_time_spent_reportable +
                        Math.min(this.time_adjustment, 0),
                    0
                );
            }
        },

        /**
         * Calculates the total amount of remaining time available for the selected block.
         *
         * This is determined as follows:
         * - If there is unused rollover time (`active_time_rollover` > 0),
         *   the available time is the block's allocated time plus any positive adjustment.
         * - If no rollover remains, the available time is calculated as:
         *   - Allocated time for the block
         *   - Plus any carried-over rollover
         *   - Minus the time already spent (`active_time_spent_reportable`)
         *   - Plus any time adjustments (`time_adjustment`), whether positive or negative.
         *
         * @returns {number} Total available time for the selected block, adjusted for rollover, time spent, and adjustments. Returns 0 if no block is selected.
         */
        active_time_available() {
            if (!this.selected_block) return 0;
            // calculate available for block
            if (this.active_time_rollover > 0) {
                // if there is rollover remaining, display full available time
                // (plus adjustment, if positive)
                return (
                    this.selected_block.time_allocated +
                    Math.max(this.time_adjustment, 0)
                );
            } else {
                // otherwise, display available time minus time spent
                // (plus adjustment)
                return (
                    this.selected_block.time_allocated +
                    (this.selected_block.rollover ?? 0) -
                    this.active_time_spent_reportable +
                    this.time_adjustment
                );
            }
        },

        /**
         * Calculates the amount of overtime for the selected block.
         *
         * This is the amount of time spent that exceeds the total available time for the block.
         *
         * Overtime is calculated as:
         * - Total time spent (`active_time_spent_reportable`)
         * - Minus the allocated time for the block
         * - Minus any time adjustments (`time_adjustment`)
         * - Minus any rollover time carried into the block
         *
         * Overtime is never negative (minimum is zero).
         *
         * @returns {number} The amount of overtime for the selected block, including active sessions if the block is ongoing. Returns 0 if there is no overtime.
         */
        active_time_overtime() {
            if (!this.selected_block) return 0;
            return Math.max(
                this.active_time_spent_reportable -
                    (this.selected_block.time_allocated +
                        this.time_adjustment) -
                    (this.selected_block.rollover ?? 0),
                0
            );
        },

        /**
         * Calculates the total time value across all segments for the selected block.
         *
         * This is the sum of:
         * - Time debt carried over from previous blocks (`time_debt`)
         * - Time already spent in the current block (`active_time_spent_reportable`)
         * - Remaining expiring rollover time (`active_time_expiring`)
         * - Remaining non-expiring rollover time (`active_time_rollover`)
         * - Available time for the current block (`active_time_available`)
         * - Rendered overtime (`rendered_overtime`)
         *
         * This represents a comprehensive total across all tracked and allocated time segments.
         *
         * @returns {number} Total time across all segments for the selected block.
         */
        full_time() {
            if (!this.selected_block) return 0;
            return (
                this.time_debt +
                this.active_time_spent_reportable +
                this.active_time_expiring +
                this.active_time_rollover +
                this.active_time_available +
                this.rendered_overtime
            );
        },

        /**
         * Ref: 'time_spent'
         * @return {number} 0 -> 100
         */
        recurring_time_spent_percentage() {
            if (!this.selected_block) return 0;
            if (!this.time_allocated) return 0;
            return (1 - this.active_time_available / this.time_allocated) * 100;
        },

        /**
         * Ref: 'time_available'
         * @return {number} 0 -> 100
         */
        recurring_time_available_percentage() {
            if (!this.selected_block) return 0;
            if (!this.time_allocated) return 0;
            return (
                (1 - this.active_time_spent_reportable / this.time_allocated) *
                100
            );
        },
    },
    watch: {
        component: {
            handler(val) {
                if (!val) {
                    this.$unbind('recurring_blocks');
                } else {
                    const now = new Date();
                    const componentRef = this.$fire.doc(`components/${val.id}`);
                    const blocksRef = this.$fire
                        .collection('component_recurring_blocks')
                        .where('component', '==', componentRef)
                        .where('start_date', '<', now)
                        .orderBy('start_date', 'asc');

                    this.$bind('recurring_blocks', blocksRef);
                }
            },
            immediate: true,
        },
    },
    methods: {
        /**
         * Get a display label for this block in the format 'DD/MMM - DD/MMM' or 'DD/MM/YY - DD/MM/YY'
         * @param {Object} block
         * @param {boolean} includeYear
         * @returns {string|null}
         */
        getDateLabelForRecurringBlock(block, includeYear = false) {
            if (!block || this.collapsed) {
                return null;
            }

            const start = momentWithTz(block.start_date.toDate());
            const end = momentWithTz(block.end_date.toDate());

            const format = includeYear ? 'DD/MM/YY' : 'DD/MMM';
            return `${start.format(format)} - ${end.format(format)}`;
        },

        /**
         * Gets the recurring block for a given date, or nearest if out of date bounds,
         * or null if there are no available blocks.
         *
         * @param date
         * @returns {{start: moment.Moment, end: moment.Moment}|null}
         */
        getRecurringBlockForDate(date) {
            if (this.recurring_blocks.length === 0) {
                return null;
            }

            const d = momentWithTz(date);

            // If date is before the first block, return the first block
            if (d.isBefore(this.recurring_blocks[0].start_date.toDate())) {
                return this.recurring_blocks[0];
            }

            // If date is after the last block, return the last block
            if (
                d.isAfter(
                    this.recurring_blocks[
                        this.recurring_blocks.length - 1
                    ].end_date.toDate()
                )
            ) {
                return this.recurring_blocks[this.recurring_blocks.length - 1];
            }

            // Find the block where the date is within start_date and end_date
            return (
                this.recurring_blocks.find((block) => {
                    const start = momentWithTz(block.start_date.toDate());
                    const end = momentWithTz(block.end_date.toDate());
                    return d.isSameOrAfter(start) && d.isSameOrBefore(end);
                }) || null
            ); // Return null if no match found (failsafe)
        },
    },
};

export default componentRecurringMixin;

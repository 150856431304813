import {COMMAND_DEPENDENCIES, USER_ROLES} from '@/enums';
import store from '@/store';

/**
 * Provides a list of invoices
 * @type {{provides: string, getResults: CommandProvider.getResults, label: string, filteredBy: null}}
 */
export const InvoiceProvider = {
    label: 'Invoices',
    provides: COMMAND_DEPENDENCIES.INVOICE,
    filteredBy: [COMMAND_DEPENDENCIES.PROJECT],
    getResults: async (data, filter_string) => {
        if (data[COMMAND_DEPENDENCIES.INVOICE]) return [];

        const is_super_admin = store.getters.session_user.role.includes(
            USER_ROLES.SUPER_ADMIN
        );

        if (!is_super_admin || !store.getters.is_xero_enabled) return [];

        if (filter_string || data[COMMAND_DEPENDENCIES.INVOICE]) {
            const query = filter_string?.toLowerCase() || '';
            return store.getters.invoices
                .filter((invoice) => {
                    return invoice.invoice_number.toLowerCase().includes(query);
                })
                .map((invoice) => {
                    return {
                        id: invoice.id,
                        label: invoice.invoice_number,
                        sub_label: '',
                        value: invoice,
                    };
                });
        }
        return null;
    },
    cleanup() {
        // if this provider holds any resources during an operation, dispose of them here
    },
};

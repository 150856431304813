import moment from 'moment';
import {PROJECT_STATUS, USER_ROLES} from '@/enums';

const getters = {
    show_tracking_bar: (state, getters) => {
        return (
            getters.currentUserCube?.active_face !== 0 || state.session_selected
        );
    },

    // USER
    ///////////////////

    session_user: (state) => state.user.object,

    users: (state) =>
        state.users
            .filter((user) => {
                return user.role !== USER_ROLES.DELETED;
            })
            .slice(0)
            .sort((a, b) => a.id - b.id),
    /**
     * Get the current user (public) information from the users table
     * This will not have details like email included. For those details use session_user.
     */
    user: (state) => {
        if (state.users && state.users.length > 0) {
            const user = state.users.find(
                (obj) => obj.id === state.user.object.id
            );
            if (user) return user;
        }
        return null;
    },
    userWithId: (state) => (value) => {
        if (value && state.users && state.users.length > 0) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            const object = state.users.find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },
    userWithName: (state) => (value) => {
        if (value && state.users && state.users.length > 0) {
            const value_split = value.split(' ');
            const name = value_split[0];
            const object = state.users.find(
                (obj) => obj.name.toLowerCase() === name.toLowerCase()
            );
            if (object) return object;
        }
        return null;
    },

    userRoles: (state) =>
        state.user_roles
            .filter((obj) => obj.active)
            .sort((a, b) => (a.order > b.order ? 1 : -1)),
    userRoleWithId: (state) => (value) => {
        if (value && state.user_roles && state.user_roles.length > 0) {
            const value_split = value.split('/');
            const id =
                value_split.length > 1
                    ? value_split[value_split.length - 1]
                    : value;
            const object = state.user_roles
                .filter((obj) => obj.active)
                .find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },

    userWithEmail: (state) => (value) => {
        if (value && state.users && state.users.length > 0) {
            const object = state.users.find((obj) => obj.email === value);
            if (object) return object;
        }
        return null;
    },

    // SESSIONS
    ///////////////////

    cubes: (state) => state.cubes,
    currentUserCube: (state, getters) =>
        getters.cubeForUserWithId(state.user.object.id),
    cubeForUserWithId: (state) => (value) => {
        if (state.cubes && state.cubes.length > 0) {
            const user = state.cubes.find((obj) => obj.id === value);
            if (user) return user;
        }
        return null;
    },

    activeSessionForUserWithId: (state) => (value) => {
        if (
            value &&
            state.active_sessions &&
            state.active_sessions.length > 0
        ) {
            const active_session = state.active_sessions.find(
                (session) => session.user === `users/${value}`
            );
            if (active_session) return active_session;
        }
        return null;
    },
    activeSessionsForComponentWithId: (state) => (value) => {
        if (
            value &&
            state.active_sessions &&
            state.active_sessions.length > 0
        ) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            return state.active_sessions.filter(
                (session) => session.component === `components/${id}`
            );
        }
        return [];
    },

    // PROJECTS
    ///////////////////
    projects: (state, getters) => getters.projectsSorted(),

    projectsSorted: (state, getters) => (sortBy = 'ref') =>
        state.projects
            .map((p) => {
                return {
                    ...p,
                    id: p.id,
                    currentStatus: getters.statusForProjectWithId(p.id),
                };
            })
            .slice(0)
            .sort((a, b) => {
                if (a[sortBy] > b[sortBy]) return 1;
                if (a[sortBy] < b[sortBy]) return -1;
                return 0;
            }),

    projectWithId: (state, getters) => (value) => {
        if (value && state.projects && state.projects.length > 0) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            const object = getters.projects.find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },
    projectWithRef: (state, getters) => (value) => {
        if (value && state.projects && state.projects.length > 0) {
            const object = getters.projects.find((obj) => obj.ref === value);
            if (object) return object;
        }
        return null;
    },
    projectForComponentWithId: (state, getters) => (value) => {
        if (
            value &&
            state.projects &&
            state.projects.length > 0 &&
            state.components &&
            state.components.length > 0
        ) {
            const component = getters.componentWithId(value);
            if (!component) return null;
            return getters.projectWithId(component?.project);
        }
        return null;
    },
    statusForProjectWithId: (state) => (value) => {
        const projectId = value.split('/').reverse()[0];
        return state.project_statuses[projectId] || null;
    },

    // STAGES
    ///////////////////

    stages: (state) => state.stages,
    stageWithId: (state) => (value) => {
        if (value && state.stages && state.stages.length > 0) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            const object = state.stages.find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },
    stagesForProjectWithId: (state) => (value) => {
        if (value && state.stages && state.stages.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.stages
                .filter((obj) => obj.project === `projects/${project_id}`)
                .sort((a, b) => {
                    if (a.number > b.number) return -1;
                    if (a.number < b.number) return 1;
                    return 0;
                })
                .map((s) => ({...s, id: s.id}));
            if (objects) return objects;
        }
        return [];
    },

    // COMPONENTS
    ///////////////////

    components: (state) => state.components,
    componentWithId: (state) => (value) => {
        if (value && state.components && state.components.length > 0) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            const object = state.components.find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },
    componentsForStageWithId: (state) => (value) => {
        if (value && state.components && state.components.length > 0) {
            const value_split = value.split('/');
            const stage_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.components
                .filter((obj) => obj.stage === `stages/${stage_id}`)
                .map((o) => ({...o, id: o.id}));
            if (objects) return objects;
        }
        return [];
    },
    componentsForProjectWithId: (state) => (value) => {
        if (value && state.components && state.components.length > 0) {
            const project_id = value.split('/').reverse(0)[0];
            const objects = state.components
                .filter((obj) => obj.project === `projects/${project_id}`)
                .map((o) => ({...o, id: o.id}));
            if (objects) return objects;
        }
        return [];
    },

    // MODULES
    ///////////////////

    modules: (state) => state.modules,
    moduleWithId: (state) => (value) => {
        if (value && state.modules && state.modules.length > 0) {
            const value_split = value.split('/');
            const module_id = value_split.length > 1 ? value_split[1] : value;
            return state.modules.find((m) => m.id === module_id) ?? null;
        }
        return null;
    },
    modulesForProjectWithId: (state) => (value) => {
        if (value && state.modules && state.modules.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.modules.filter(
                (obj) => obj.project_id === project_id
            );
            if (objects) return objects;
        }
        return [];
    },

    // CHANGE REQUESTS
    ///////////////////

    changeRequests: (state) => {
        if (!state.change_requests) return [];
        let closed = [];
        if (state.project_change_requests) {
            closed = Object.values(state.project_change_requests).reduce(
                (acc, val) => {
                    if (!val || !val.closed) return acc;
                    return [...acc, ...val.closed];
                },
                []
            );
        }
        return [...closed, ...state.change_requests];
    },
    changeRequestWithId: (state, getters) => (value) => {
        if (
            value &&
            getters.changeRequests &&
            getters.changeRequests.length > 0
        ) {
            const value_split = value.split('/');
            const id = value_split.length > 1 ? value_split[1] : value;
            const object = getters.changeRequests.find((obj) => obj.id === id);
            if (object) return object;
        }
        return null;
    },
    changeRequestsForProjectWithIdLoaded: (state) => (value) => {
        const value_split = value.split('/');
        const project_id = value_split.length > 1 ? value_split[1] : value;

        if (
            state.project_change_requests &&
            state.project_change_requests[project_id]
        ) {
            const cr = state.project_change_requests[project_id];
            return {
                open: true,
                closed: !!cr.closed,
            };
        }

        return {open: true, closed: false};
    },
    changeRequestsForProjectWithId: (state, getters) => (value) => {
        const value_split = value.split('/');
        const project_id = value_split.length > 1 ? value_split[1] : value;

        const open = getters.changeRequests.filter(
            (cr) => cr.project === `projects/${project_id}`
        );

        const closed = [];

        if (
            state.project_change_requests &&
            state.project_change_requests[project_id]
        ) {
            closed.push(
                ...(state.project_change_requests[project_id].closed ?? [])
                    .slice(0)
                    .sort((a, b) => {
                        // Sort by ID
                        if (a.ref > b.ref) return 1;
                        if (a.ref < b.ref) return -1;
                        return 0;
                    })
                    .sort((a, b) => b.status - a.status)
            );
        }
        return [...open, ...closed];
    },
    changeRequestsForModuleWithId: (state, getters) => (
        project_id,
        module_id
    ) => {
        const value_split = project_id.split('/');
        const projectid = value_split.length > 1 ? value_split[1] : project_id;

        const open = getters.changeRequests.filter(
            (cr) =>
                cr.project === `projects/${project_id}` &&
                cr.modules?.includes(`modules/${module_id}`)
        );

        const closed = [];

        if (
            state.project_change_requests &&
            state.project_change_requests[projectid]
        ) {
            closed.push(
                ...(state.project_change_requests[project_id].closed ?? [])
                    .filter((obj) =>
                        obj.modules?.includes(`modules/${module_id}`)
                    )
                    .sort((a, b) => {
                        // Sort by ID
                        if (a.ref > b.ref) return 1;
                        if (a.ref < b.ref) return -1;
                        return 0;
                    })
                    .sort((a, b) => {
                        // Sort by progress
                        if (a.status < b.status) return 1;
                        if (a.status > b.status) return -1;
                        return 0;
                    })
            );
        }
        return [...open, ...closed];
    },

    // PATHS
    ///////////////////

    paths: (state) => state.paths,
    pathsForProjectWithId: (state) => (value) => {
        if (value && state.paths && state.paths.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.paths.filter(
                (obj) => obj.project === `projects/${project_id}`
            );
            if (objects) return objects;
        }
        return [];
    },

    // CREDENTIALS
    ///////////////////

    credentials: (state) => state.credentials,
    credentialsForProjectWithId: (state) => (value) => {
        if (value && state.credentials && state.credentials.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.credentials.filter(
                (obj) => obj.project === `projects/${project_id}`
            );
            if (objects) return objects;
        }
        return [];
    },

    // VALUES
    ///////////////////

    values: (state) => state.values,
    valuesForProjectWithId: (state) => (value) => {
        if (value && state.values && state.values.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.values.filter(
                (obj) => obj.project === `projects/${project_id}`
            );
            if (objects) return objects;
        }
        return [];
    },

    // CLIENTS
    ///////////////////

    clients: (state) =>
        state.clients.slice(0).sort((a, b) => a.name.localeCompare(b.name)),
    clientWithId: (state) => (value) => {
        if (value && state.clients && state.clients.length > 0) {
            const value_split = value.split('/');
            const client_id = value_split.length > 1 ? value_split[1] : value;
            const object = state.clients.find((obj) => obj.id === client_id);
            if (object) return object;
        }
        return null;
    },
    clientsForProjectWithId: (state) => (value) => {
        if (value && state.clients && state.clients.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            return state.clients.filter((obj) => {
                if (obj.projects)
                    return obj.projects.includes(`projects/${project_id}`);
                else return false;
            });
        }
        return [];
    },

    // INVOICES
    ///////////////////

    invoices: (state) =>
        state.invoices
            .filter((i) => i.invoice_number) // Remove incomplete Invoices - they are filtered Backend side as well
            .slice(0)
            .sort((a, b) => {
                if (a.invoice_number < b.invoice_number) return 1;
                if (a.invoice_number > b.invoice_number) return -1;
                return 0;
            }),
    invoiceWithId: (state) => (value) => {
        if (value && state.invoices && state.invoices.length > 0) {
            const value_split = value.split('/');
            const invoice_id = value_split.length > 1 ? value_split[1] : value;
            const object = state.invoices.find(
                (obj) =>
                    obj.id === invoice_id || obj.invoice_number === invoice_id
            );
            if (object) return object;
        }
        return null;
    },
    invoicesForClientWithId: (state) => (value) => {
        if (value && state.invoices && state.invoices.length > 0) {
            const value_split = value.split('/');
            const client_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.invoices.filter((obj) => {
                return obj.contact_id === client_id;
            });
            if (objects) return objects;
        }
        return [];
    },

    // ESTIMATES
    ///////////////////

    estimates: (state) => state.estimates,
    estimatesForProjectWithId: (state) => (value) => {
        if (value && state.estimates && state.estimates.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            return state.estimates
                .filter((obj) => obj.project === `projects/${project_id}`)
                .map((e) => ({...e, id: e.id}));
        }
        return [];
    },
    estimateWithId: (state) => (value) => {
        if (value && state.estimates && state.estimates.length > 0) {
            const value_split = value.split('/');
            const estimate_id = value_split.length > 1 ? value_split[1] : value;
            const object = state.estimates.find(
                (obj) => obj.id === estimate_id
            );
            if (object) return object;
        }
        return null;
    },

    // PAYMENT PLANS
    ///////////////////

    paymentPlans: (state) => state.payment_plans,
    paymentPlanWithId: (state) => (value) => {
        if (value && state.payment_plans && state.payment_plans.length > 0) {
            const value_split = value.split('/');
            const payment_plan_id =
                value_split.length > 1 ? value_split[1] : value;
            const object = state.payment_plans.find(
                (obj) => obj.id === payment_plan_id
            );
            if (object) return object;
        }
        return null;
    },
    paymentPlansForEstimateWithId: (state) => (value) => {
        if (value && state.payment_plans && state.payment_plans.length > 0) {
            const value_split = value.split('/');
            const estimate_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.payment_plans.filter((obj) => {
                return obj.estimate === `estimates/${estimate_id}`;
            });
            if (objects) return objects;
        }
        return [];
    },
    paymentPlansForProjectWithId: (state) => (value) => {
        if (value && state.payment_plans && state.payment_plans.length > 0) {
            const value_split = value.split('/');
            const project_id = value_split.length > 1 ? value_split[1] : value;
            const objects = state.payment_plans.filter((obj) => {
                return obj.project === `projects/${project_id}`;
            });
            if (objects) return objects;
        }
        return [];
    },

    // EVENTS
    ////////////////////

    eventTypes: (state) => () => {
        return state.event_types || [];
    },
    eventTypeForType: (state) => (type) => {
        return state.event_types.find((obj) => obj.type === type);
    },
    eventTypeWithId: (state) => (event_type_id) => {
        return state.event_types.find(
            (obj) => `system/events/types/${obj.id}` === event_type_id
        );
    },
    eventsForProjectWithId: (state) => (project_id) => {
        if (state.events && state.events.length > 0) {
            return state.events
                .filter((obj) => obj.project === `projects/${project_id}`)
                .map((e) => ({...e, id: e.id}));
        }
        return [];
    },
    events: (state) => state.events || [],
    eventWithId: (state) => (event_id) => {
        return state.events.find((obj) => obj.id === event_id);
    },
    currentStatusForProjectWithId: (state) => (project_id) => {
        const statusType = state.event_types.find(
            (type) => type.type === 'status'
        );

        if (!statusType) return null;

        const currentStatus = state.events
            .filter((event) => event.project === `projects/${project_id}`)
            .slice(0)
            .sort((a, b) => {
                if (a.created_at.toDate() < b.created_at.toDate()) return 1;
                if (b.created_at.toDate() < a.created_at.toDate()) return -1;
                return 0;
            })
            .find(
                (event) => event.type === `system/events/types/${statusType.id}`
            );

        if (currentStatus) return currentStatus;
        return null;
    },
    currentMilestoneForProjectWithId: (state) => (project_id) => {
        const milestoneType = state.event_types.find(
            (type) => type.type === 'milestone'
        );

        if (!milestoneType) return null;

        const currentDate = new Date();
        const currentMilestones = state.events
            .filter(
                (event) =>
                    event.project === `projects/${project_id}` &&
                    event.type == `system/events/types/${milestoneType.id}` &&
                    event.start.toDate() > currentDate
            )
            .slice(0)
            .sort((a, b) => {
                if (a.start.toDate() < b.created_at.toDate()) return -1;
                if (b.created_at.toDate() < a.created_at.toDate()) return 1;
                return 0;
            });

        if (currentMilestones.length > 0) return currentMilestones[0];
        return null;
    },

    // ATLASSIAN
    ///////////////////

    activeProjectsWithConfluence: (_, getters) => {
        return getters.projects.filter(
            (project) =>
                getters.is_confluence_enabled &&
                project.status !== PROJECT_STATUS.ARCHIVED &&
                project.confluence_org &&
                project.confluence_space
        );
    },
    activeProjectsWithJira: (_, getters) => {
        return getters.projects.filter(
            (project) =>
                getters.is_jira_enabled &&
                project.status !== PROJECT_STATUS.ARCHIVED &&
                project.jira_org &&
                project.jira_project
        );
    },

    issueWithKey: (state) => (issue_id) => {
        const issue = state.jira_issues.find((i) => i.key === issue_id);
        return issue ?? null;
    },
    issueWithDocumentPath: (state) => (path) => {
        const issue = state.jira_issues.find((i) => i.path === path);
        return issue ?? null;
    },
    issueWithDocumentId: (state) => (doc_id) => {
        const issue = state.jira_issues.find((i) => i.id === doc_id);
        return issue ?? null;
    },
    issuesForJiraProject: (state) => (jira_project) => {
        return state.jira_issues.filter(
            (i) => i.jira_project.label === jira_project
        );
    },
    userIssues: (state, getters) => {
        if (!getters.session_user.is_atlassian_authed) return [];

        try {
            return state.jira_issues
                .filter((issue) => {
                    return (
                        issue.assignee?.email?.toLowerCase() ===
                        (
                            getters.session_user.atlassian_api_user ||
                            getters.session_user.email
                        )?.toLowerCase()
                    );
                })
                .filter(
                    (issue) =>
                        issue.status.label.toLowerCase() !== 'done' &&
                        issue.status.label.toLowerCase() !== 'closed' &&
                        issue.status.label.toLowerCase() !== 'backlog'
                )
                .map((issue) => {
                    const project = getters.activeProjectsWithJira.find(
                        (p) => p.jira_project === issue.jira_project.label
                    );

                    return {...issue, project};
                })
                .filter((p) => p.project);
        } catch (e) {
            console.log(e);
        }
    },
    getProjectIssues: (state, getters) => (project_id) => {
        if (!getters.session_user.is_atlassian_authed) return [];

        const project = getters.projectWithId(project_id);
        if (!project || project.status === PROJECT_STATUS.ARCHIVED) return [];
        if (!project.jira_org || !project.jira_project) return [];

        //TODO: priorities and sorting
        return state.jira_issues.filter((issue) => {
            return (
                issue.resource.name === project.jira_org &&
                issue.jira_project.label === project.jira_project
            );
        });
    },
    issuesForProjectWithId: (state, getters) => (project_id) => {
        const project = getters.projectWithId(project_id);
        return getters
            .getProjectIssues(project_id)
            .filter(
                (issue) =>
                    issue.status.label.toLowerCase() !== 'done' &&
                    issue.status.label.toLowerCase() !== 'closed' &&
                    issue.status.label.toLowerCase() !== 'backlog' &&
                    issue.status.label.toLowerCase() !== 'roadmap'
            )
            .map((issue) => {
                return {...issue, project};
            });
    },

    // TIME
    ///////////////////

    current_date: (state) => {
        return moment(state.current_time).format('YYYY-MM-DD');
    },

    organisation: (state) => {
        return state.organisation;
    },
};

export default getters;

<template>
    <el-dialog
        :visible.sync="show"
        width="45%"
        custom-class="settings-modal"
        @closed="onCloseDialog"
    >
        <el-tabs v-model="active_tab" @tab-click="handleTabChange">
            <el-tab-pane label="Integrations" name="integrations">
                <integrations-settings :show_dialog="show_dialog" />
            </el-tab-pane>

            <el-tab-pane label="Profile" name="profile">
                <user-profile-settings
                    :user="current_user"
                    :show_dialog="show_dialog"
                />
            </el-tab-pane>

            <el-tab-pane
                v-if="user_is_super_admin"
                label="Organisation"
                name="organisation"
            >
                <organisation-settings :show_dialog="show_dialog" />
            </el-tab-pane>
        </el-tabs>

        <div slot="footer" class="dialog-footer">
            <el-button
                :loading="loading.logout"
                type="danger"
                plain
                @click="handleLogout"
            >
                Logout
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import UserProfileSettings from '@/components/settings/UserProfileSettings';
import OrganisationSettings from '@/components/settings/OrganisationSettings';
import IntegrationsSettings from '@/components/settings/IntegrationsSettings';

import userMixin from '@/mixins/user.mixin';

import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

export default {
    name: 'settings-modal',
    components: {
        UserProfileSettings,
        OrganisationSettings,
        IntegrationsSettings,
    },
    mixins: [userMixin],
    props: {
        show_dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            SUPPORTED_FEATURES: SUPPORTED_FEATURES,
            active_tab: 'integrations',
            show: this.show_dialog,
            loading: {
                logout: false,
            },
        };
    },
    watch: {
        show_dialog: function (val) {
            this.show = val;
        },
    },
    methods: {
        onCloseDialog() {
            this.$emit('update:show_dialog', false);
        },
        handleTabChange(tab) {
            this.active_tab = tab.name;
        },
        handleLogout() {
            console.log('Logging out...');
            this.loading.logout = true;
            this.$router.push({
                name: 'logout',
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
#pane-integrations {
    &:has(.container) .placeholder {
        display: none;
    }
}

::v-deep .container {
    padding: 20px;
    border: 1px solid $border-grey-light;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.description {
        flex-direction: column;
        align-items: flex-start;
    }

    p {
        margin: 0 0 20px 0;
        font-size: 11px;
        text-wrap: balance;
        word-break: normal;
    }

    label {
        margin: 0;
        font-weight: 400;

        a {
            display: block;
            font-weight: bold;
            color: $blue;
            text-decoration: none;
            font-size: 10px;
            margin-top: 5px;
        }
    }

    .right {
        display: flex;
        align-items: center;

        .el-input {
            width: 200px;
        }

        .col + .el-button {
            width: 100px !important;
            margin-left: 5px;
            height: 60px;
            margin-top: 2px;
            flex: unset;
        }
    }

    .el-button,
    .el-input {
        flex: 1;
    }

    .avatar {
        margin-right: 20px;
    }

    .el-progress {
        margin-bottom: 0;
    }

    div.col {
        flex: 1;
        display: flex;
        flex-direction: column;

        .el-input {
            margin: 2px 0;
        }
    }

    div.row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

::v-deep .el-dialog.settings-modal {
    .el-dialog__body {
        padding: 0 20px;
    }

    .el-tabs__content {
        overflow: visible;
    }

    .el-dialog__headerbtn {
        z-index: 5000;
    }

    .el-tabs__nav-wrap {
        &::after {
            height: 1px !important;
            background-color: $border-grey-light;
        }
    }
}
</style>

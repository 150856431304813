<template>
    <div class="color-picker">
        <slot class="prepend" name="prepend" />
        <div class="color" :style="{'--color': value}" @click="togglePicker" />
        <div v-if="show" class="picker">
            <div
                v-for="color of colors"
                :key="color"
                class="color"
                :style="{'--color': color}"
                @click="() => handleSelect(color)"
            />
        </div>
        <span class="append"><slot name="append" /></span>
    </div>
</template>

<script>
export default {
    props: {
        colors: {
            type: Array,
            default: () => [
                // '#040404',
                // '#ffffff',
                // '#cecece',
                // '#91a2a5',
                '#f92239',
                '#ec6a3d',
                '#f09d39',
                '#e8c342',
                '#adcd4f',
                '#7fcf79',
                '#64c2ac',
                '#4ea9e5',
                '#4f86da',
                '#7673e8',
                '#9f69dc',
                '#d36ddd',
                '#d95a9b',
                '#ee96ad',
            ],
        },
        value: {
            type: String,
            default: '#000000',
        },
        show_dialog: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            show: false,
        };
    },
    watch: {
        show_dialog: function (val) {
            this.show = false;
        },
    },
    methods: {
        togglePicker() {
            this.show = !this.show;
        },

        handleSelect(color) {
            this.$emit('input', color);
            this.$emit('select', color);
            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
$size: 20px;
$borderWidth: 2px;
$perRow: 4;

.color-picker {
    position: relative;
    clear: both;
    display: flex;
    align-items: center;

    .append {
        margin-left: 10px;
    }
}

.picker {
    display: flex;
    flex-wrap: wrap;
    max-width: $perRow * $size + ($borderWidth * ($perRow * 2));
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $border-grey-light;
    padding: 10px;
    border-radius: 10px;
    z-index: 10001;
    box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
}

.color {
    display: block;
    width: $size;
    height: $size;
    margin: $borderWidth;
    background-color: var(--color);
    border-radius: 2px;
    border: 1px solid $border-grey-light;
    box-sizing: border-box;
}
</style>

import router from '@/router';
import {validateRoute} from '@/utils/validate';
import store from '../store';
import {USER_ROLES} from '@/enums';
import {SUPPORTED_FEATURES} from '@/store/modules/feature-toggles';

const whiteList = ['/login', '/access/(.*)'];
const superAdminOnlyList = [];
const adminOnlyList = [
    '/business',
    '/manage/(.*)',
    '/project/(.*)/paymentplan/(.*)',
];
const toggledRoutes = [
    {
        disabled: () => !store.getters.is_planner_enabled,
        routes: ['/planner/(.*)'],
    },
    {
        disabled: () => !store.getters.is_gcal_enabled,
        routes: ['/events'],
        redirect: '/team',
    },
    {
        disabled: () => !store.getters.is_xero_enabled,
        routes: [
            '/manage/invoices',
            '/manage/invoice/(.*)',
            '/manage/paymentplans',
        ],
        redirect: '/manage/clients',
    },
    {
        disabled: () => !store.getters.are_todo_screens_enabled,
        routes: ['/todo', '/todos'],
        redirect: '/team',
    },
    {
        disabled: () =>
            !store.getters.is_feature_enabled(
                SUPPORTED_FEATURES.PROJECT_ESTIMATES
            ),
        routes: [
            '/project/(.*)/estimates',
            '/project/(.*)/estimates/(.*)',
            '/project/(.*)/paymentplan',
            '/project/(.*)/paymentplan/(.*)',
            '/project/(.*)/paymentplan/invoice/(.*)',
        ],
        redirect: '/todo',
    },
    {
        disabled: () =>
            !store.getters.is_feature_enabled(
                SUPPORTED_FEATURES.PROJECT_CHANGE_REQUESTS
            ),
        routes: [
            '/project/(.*)/changerequests',
            '/project/(.*)/changerequest/(.*)',
            '/project/(.*)/stage/(.*)/component/(.*)/changerequests',
        ],
        redirect: '/todo',
    },
    {
        disabled: () =>
            !store.getters.is_feature_enabled(SUPPORTED_FEATURES.PROJECT_UTILS),
        routes: ['/project/(.*)/utils'],
        redirect: '/todo',
    },
];

router.beforeEach(async (to, from, next) => {
    // verify user is logged in and user auth data is available
    if (store.getters.session_user) {
        if (to.path === '/login') {
            // User wants to access auth pages - redirect to home page
            next({name: 'home'});
        } else {
            // User wants to access dashboard pages - everything is ok
            const role = store.getters.session_user.role;

            // Check if user can access the selected page

            // Check if page is part of a feature toggle
            const matchingFeatureToggleRoutes = toggledRoutes.filter((route) =>
                validateRoute(to.path, route.routes)
            );
            if (matchingFeatureToggleRoutes.length) {
                // if the user navigated directly to the uri,
                // the toggles may not have been fetched from firebase yet -> force fetch them
                await store.dispatch('fetchFeatureToggles');
            }
            const disabledRoute = matchingFeatureToggleRoutes.find((route) =>
                route.disabled()
            );

            // Check if the route is disabled by a feature
            if (disabledRoute) {
                next(disabledRoute.redirect ?? '/');
            }
            // Check if user is not a super admin but the route is protected
            // Otherwise redirect to home
            else if (
                validateRoute(to.path, superAdminOnlyList) &&
                !role.includes(USER_ROLES.SUPER_ADMIN)
            ) {
                next('/');
            }
            // Check if user is not an admin but the route is protected
            // Otherwise redirect to home
            else if (
                validateRoute(to.path, adminOnlyList) &&
                !role.includes(USER_ROLES.ADMIN)
            ) {
                next('/');
            } else {
                next();
            }
        }
    } else {
        // User is not logged in
        if (validateRoute(to.path, whiteList)) {
            next();
        } else {
            next('/login');
        }
    }
});

import axios from 'axios';
import {Message, Notification} from 'element-ui';
import store from '../store';
import {firebaseApp} from '@/utils/firebase';

const ERROR_CODES = Object.freeze({
    JIRA: 308,
});

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI,
    timeout: 30000,
});

service.source = axios.CancelToken.source();
service.isCancel = axios.isCancel;

// request
service.interceptors.request.use(
    (config) => {
        config.headers['Access-Control-Allow-Origin'] = '*';

        if (!config.cancelToken) {
            config.cancelToken = service.source.token;
        }

        const firebaseUser = firebaseApp.auth().currentUser;
        if (firebaseUser) {
            return (async () => {
                const token = await firebaseUser.getIdToken();
                config.headers['Authorization'] = 'Bearer ' + token;
                return config;
            })();
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// response
service.interceptors.response.use(
    (response) => {
        const res = response;

        if (res.status >= 200 && res.status <= 299) {
            return response.data;
        } else {
            return Promise.reject('error');
        }
    },
    (error) => {
        if (service.isCancel(error)) {
            console.log('Request cancelled: ', error.message);
        } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
        ) {
            let message = error.response.data.message;

            // special case error handling
            switch (error.response.data.code) {
                case ERROR_CODES.JIRA:
                    if (error.response.status !== 403) {
                        message = 'Issue accessing JIRA:';
                        try {
                            const errData = JSON.parse(
                                error.response.data.data?.error
                            );
                            errData.errorMessages?.forEach((msg) => {
                                // append error message, reformatted if the pattern matches
                                message +=
                                    '<br>' +
                                    msg.replace(
                                        /The value '(.*)' does not exist for the field '(.*)'\./i,
                                        'Invalid $2: $1'
                                    );
                            });
                        } catch (e) {
                            //
                        }
                        showErrorMessage('Error', message, true);
                    }
                    break;
                default:
                    showErrorMessage('Error', message);
                    break;
            }

            if (error.response.status < 500) return Promise.reject(error);
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000,
            });
        }

        return Promise.reject(error);
    }
);

function showErrorMessage(title, message, formatted = false) {
    setTimeout(function () {
        Notification({
            type: 'error',
            title: title,
            message: message,
            dangerouslyUseHTMLString: formatted,
        });
    }, 50);
}

export default service;

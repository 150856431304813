import {COMPONENT_STATUS, PROJECT_STATUS, STAGE_STATUS} from '@/enums';

const componentSessionMixin = {
    computed: {
        cube() {
            return this.$store.getters.cubeForUserWithId(
                this.$store.getters.user.id
            );
        },

        /**
         * All the active sessions for the given stage
         * @return {sessions[]}
         */
        active_sessions() {
            if (!this.component) return [];

            // If user is defined only grab that specific session
            if (this.user) {
                const session = this.$store.getters.activeSessionForUserWithId(
                    this.user.id
                );

                // Make sure this active session is running on the selected component
                if (
                    session &&
                    session.component === `components/${this.component.id}`
                ) {
                    return [session];
                }

                // This user doesn't have any active session
                return [];
            }

            // Grab every active session for the component
            return this.$store.getters.activeSessionsForComponentWithId(
                this.component.id
            );
        },

        /**
         * Flags
         * --------------------------
         * If the given component is associated with a cube face
         * For the current user
         * @return {boolean}
         */
        is_associated() {
            if (!this.cube) return false;
            return this.cube.face2component[this.face_id];
        },

        /**
         * If the given component is currently tracking
         * For the current user
         * @return {boolean}
         */
        is_active() {
            if (!this.cube) return false;
            return this.cube.active_face === this.face_id;
        },

        /**
         * If the given component allows tracking
         * @return {boolean}
         */
        can_track_time() {
            if (!this.is_associated) return false;
            if (
                this.project &&
                this.project.status === PROJECT_STATUS.ARCHIVED
            ) {
                return false;
            }

            if (
                this.component &&
                this.component.status !== COMPONENT_STATUS.ACTIVE
            )
                return false;

            if (!this.stage) return false;
            if (this.stage.status !== STAGE_STATUS.ACTIVE) return false;

            return true;
        },

        /**
         * Time
         * --------------------------
         * How much time has been spent for the given component
         * -- Only for active sessions
         * @return {number} time in milliseconds
         */
        tspent__active_sessions() {
            if (this.active_sessions.length) {
                return (
                    this.active_sessions
                        .map((session) => {
                            if (!session.start) return 0; // TODO firebase is delaying setting the serverTimestamp value??
                            return this.$options.filters.secondsDiff(
                                session.start.seconds,
                                this.$store.state.current_time / 1000
                            );
                        })
                        .reduce((a, b) => a + b, 0) * 1000
                );
            }
            return 0;
        },
        /**
         * How much time has been estimated for the given component
         * @return {number} time in milliseconds
         */
        time_estimate() {
            if (!this.component) return 0;

            return this.component.time_estimate ?? 0;
        },

        /**
         * How much time has been spent for the given component
         * -- Only for closed sessions
         * @return {number} time in milliseconds
         */
        tspent__closed_sessions() {
            if (!this.component) return 0;

            return this.$options.filters.getTotalTimeSpentInMillis(
                this.component
            );
        },

        /**
         * How much time has been spent for the given component
         * @return {number} time in milliseconds
         */
        time_spent() {
            return this.tspent__closed_sessions + this.tspent__active_sessions;
        },

        /**
         * Ref: 'time_spent'
         * @return {number} 0 -> 100
         */
        time_spent_percentage() {
            if (!this.time_left || !this.time_estimate) return 100;
            return (1 - this.time_left / this.time_estimate) * 100;
        },

        /**
         * How much time has been reported for the given component
         * @return {number} time in milliseconds
         */
        time_spent_reportable() {
            if (this.component.time_reportable) {
                const reportable = Object.entries(
                    this.component.time_reportable
                ).reduce((sum, day) => {
                    const sessions = Object.entries(day[1]);
                    return sessions.reduce((daySum, session) => {
                        return daySum + session[1];
                    }, sum);
                }, 0);

                return reportable + this.tspent__active_sessions;
            }

            return this.tspent__closed_sessions + this.tspent__active_sessions;
        },

        /**
         * Ref: 'time_reportable'
         * @return {number} 0 -> 100
         */
        time_spent_reportable_percentage() {
            if (!this.time_left_reportable || !this.time_estimate) return 100;
            return (1 - this.time_left_reportable / this.time_estimate) * 100;
        },

        /**
         * How much time is left for the given component against the estimate
         * @return {number} time in milliseconds
         */
        time_left() {
            return this.time_estimate - this.time_spent;
        },

        /**
         * Ref: 'time_left'
         * @return {number} 0 -> 100
         */
        time_left_percentage() {
            if (!this.time_spent || !this.time_estimate) return 0;
            return (1 - this.time_spent / this.time_estimate) * 100;
        },

        /**
         * How much reportable time is left for the given component against the estimate
         * @return {number} time in milliseconds
         */
        time_left_reportable() {
            return this.time_estimate - this.time_spent_reportable;
        },

        /**
         * Ref: 'time_reportable'
         * @return {number} 0 -> 100
         */
        time_left_reportable_percentage() {
            if (!this.time_spent_reportable || !this.time_estimate) return 100;
            return (1 - this.time_spent_reportable / this.time_estimate) * 100;
        },
    },
};

export default componentSessionMixin;
